import { render, staticRenderFns } from "./promotionDetails.vue?vue&type=template&id=53b127bf&scoped=true&"
import script from "./promotionDetails.vue?vue&type=script&lang=js&"
export * from "./promotionDetails.vue?vue&type=script&lang=js&"
import style0 from "./promotionDetails.vue?vue&type=style&index=0&id=53b127bf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53b127bf",
  null
  
)

export default component.exports